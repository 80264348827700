<template>
  <div class="container">
    <section class="hero is-large is-primary herospacetop">
      <div class="hero-body">
        <div class="coloumns is-centered">
          <div class="column is-full">
            <p class="title">TechWednesday</p>
            <p class="subtitle">
              Offensichtlich bauen wir noch an unserer Seite…
            </p>
            <p class="subtitle spacertop">
              Totzdem wollen wir hier schon Themen für den TechWednesday
              sammeln!
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-large is-primary">
      <div class="hero-body">
        <div class="columns">
          <div class="column">
            <ValidationObserver
              ref="observer"
              v-slot="{ passes }"
              v-if="isEntry"
            >
              <div class="container kontaktdaten has-text-left">
                <div class="columns is-multiline">
                  <div class="coloumn column is-8">
                    <ValidationProvider
                      rules="required"
                      name="thema"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        label="Dein Themenvorschlag"
                        :type="{ 'is-twitter': errors[0], 'is-success': valid }"
                        :message="errors"
                      >
                        <b-input
                          custom-class="is-large"
                          v-model="thema"
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="coloumn column is-4">
                    <b-field label="von*">
                      <b-input
                        custom-class="is-large"
                        v-model="absender"
                      ></b-input>
                    </b-field>
                    <p class="labeldescription">*optional</p>
                  </div>
                </div>
              </div>
              <b-button
                type="is-info"
                size="is-large"
                class="spacetop"
                @click="passes(themaabsenden)"
                :loading="isLoading"
                >Thema eintragen</b-button
              >
            </ValidationObserver>

            <b-notification
              type="is-twitter"
              aria-close-label="Close notification"
              :closable="false"
              v-if="isSuccess"
            >
              Danke für den Themenvorschlag! Beim nächsten TechWednesday werden
              alle Themen kurz vorgestellt, dann entscheidet das Publikum, wie
              es weitergeht. Wir freuen uns schon!
            </b-notification>
            <b-notification
              type="is-twitter"
              aria-close-label="Close notification"
              :closable="false"
              v-if="isError"
            >
              Hier ist gerade etwas schief gegangen - bitte versuche es später
              noch einmal.
            </b-notification>
          </div>
          <div class="column">
            <p class="subtitle spacertop">
              Folgende Themen wurden schon vorgeschlagen:
            </p>
            <b-notification :closable="false" type="is-info">
              <div class="themenliste">
                <b-notification
                  type="is-info"
                  aria-close-label="Close notification"
                  :closable="false"
                  v-if="(new Date(posts.data.results[0].properties.Date.date.start)).getTime() < blocktatetimestamp"
                >
                  Leider wurde noch kein Thema vorgeschlagen - du kannst es
                  jederzeit ändern!
                </b-notification>

                <div
                  v-for="result in posts.data.results"
                  v-bind:key="result.id"
                >
                  <p
                    class="is-size-5"
                    v-if="result.properties.Status.select.name != blockstatus && (new Date(result.properties.Date.date.start)).getTime() >= blocktatetimestamp"
                  >
                    {{ result.properties.Thema.rich_text[0].text.content }}
                    <span
                      class="is-size-7"
                      v-if="
                        result.properties.Absender.rich_text[0].plain_text !==
                        ''
                      "
                    >
                      von
                      {{ result.properties.Absender.rich_text[0].plain_text }}
                    </span>
                  </p>
                </div>
              </div>
              <b-loading
                :is-full-page="isFullPage"
                v-model="isLoading2"
                :can-cancel="true"
              ></b-loading>
            </b-notification>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-large is-primary">
      <div class="hero-body">
        <div class="coloumns is-centered">
          <div class="column is-full">
            <p class="subtitle">
              Der TechWednesday findet jeden ersten Mittwoch im Monat statt.
              Aktuell rein digital, bald hoffentlich aber wieder in Hybrid.
            </p>
            <p class="subtitle">
              Um 18:00 geht es los. Die Veranstaltung dauert 90 Minuten.
            </p>
            <!--<p class="subtitle">
              Der TechWednesday findet jeden ersten Mittwoch im Monat sowohl
              digital als auch in Präsenz in Hybrid statt.
            </p>
            <p class="subtitle">
              Da wir vor Ort nur eine begrenzte Teilnehmer*innen Zahl zulassen
              können, bitten wir hier um eine Anmeldung. Aufgrund der aktuellen
              Corona-Bedingungen treffen wir uns um 17:45 beim Haupteingang vom
              H-Gebäude. Hier werden die 3G Nachweise kontrolliert, bevor wir in
              den Raum H202 gehen. Um 18:00 geht es dann für alle los. Die
              Veranstaltung geht 90 Minuten.
            </p>-->
            <div class="buttons">
              <!--<b-button
                type="is-primary"
                size="is-medium"
                class=""
                tag="a"
                href="https://hs-flensburg.de/hochschule/veranstaltungen/2021/12/1/techwednesday-micro-barcamp-der-hs-flensburg"
                target="_blank"
                inverted
              >
                zur „vor Ort” Anmeldung
              </b-button>-->
              <b-button
                type="is-primary"
                size="is-medium"
                class=""
                tag="a"
                href="https://hs-flensburg.webex.com/hs-flensburg/j.php?MTID=m62f6b2ea6248a58b7b36b21c07b51dde"
                target="_blank"
                inverted
              >
                zum Webex-Raum
              </b-button>
              <b-button
                type="is-primary"
                size="is-medium"
                class=""
                tag="a"
                href="https://hs-flensburg.de/forschung/fue/forschungsprojekte/techstartuphs-flensburg-testup"
                target="_blank"
                inverted
                outlined
              >
                mehr über TeStUp
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script type="text/babel">
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, min } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
extend("required", {
  ...required,
  message: "Dieses Feld muss ausgefüllt werden.",
});

extend("min", {
  ...min,
  message: "Bitte trage mehr Zeichen ein.",
});

export default {
  name: "TechWednesday",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      blockstatus: "abgelehnt",
      blockdate: "2022-03-02",
      blocktatetimestamp: "",
      thema: "",
      absender: "",
      isLoading2: true,
      isFullPage: false,
      isLoading: false,
      isError: false,
      isSuccess: false,
      isEntry: true,
      posts: {
        data: {
          results: {},
        },
      },
    };
  },
  methods: {
    async getData() {
      try {
        let response = await fetch(
          "https://testup-testup.vercel.app/api/readtechwednesday"
        );
        this.posts = await response.json();
        this.isLoading2 = false;
      } catch (error) {
        console.log(error);
      }
    },
    async themaabsenden() {
      this.isLoading = true;
      try {
        const res = await fetch(
          "https://testup-testup.vercel.app/api/writetechwednesday",
          {
            method: "POST",
            body: JSON.stringify({
              subject: this.thema,
              author: this.absender,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );
        if (res.status === 200) {
          this.isSuccess = true;
          this.isEntry = false;
          this.isLoading = false;
          this.getData();
        } else {
          this.isError = true;
          this.isEntry = false;
          //console.log("Could not process PayPal Payment.");
        }
        //console.log(JSON.stringify(res, null, 2));
      } catch (error) {
        this.isError = true;
        this.isEntry = false;
        console.log(error);
      }
    },
  },
  created() {
    this.getData();
    var myblockdate = new Date(this.blockdate);
    var timestampdate = myblockdate.getTime();
    this.blocktatetimestamp = timestampdate;
  },
};
</script>

<style lang="scss">
.herospacetop {
  padding-top: 10rem;
}
.label {
  color: $white;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1.25rem !important;
}
.help {
  font-weight: bold;
  font-size: 1rem;
}
.loading-overlay .loading-background {
  background: $info;
}

.labeldescription {
  margin-top: -0.5rem;
}
</style>
